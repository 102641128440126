<template>
    <div class="header-card">
        <div 
            v-if="!hideThumb" 
            class="thumb w-100" 
            :style="mainInfo.preview ? {'background-image': `url(${ mainInfo.preview })`} : {}" 
        />
        <div v-if="!mainInfo.name" class="w-100 d-flex justify-content-center mt-3">
            <b-spinner variant="success m-5"></b-spinner>
        </div>
        <div class="d-flex content">
            <div class="profile-pic" :style="mainInfo.avatar ? {'background-image': `url(${mainInfo.avatar})`} : {}" />
            <div class="description">
                <router-link :to="(company ? '/company/' : '/user/') + mainInfo.id" class="d-flex router-link ">
                    <h4 class="d-flex align-items-center justify-content-between text-dark mt-2">
                        {{ mainInfo.name }} 
                        <b-icon-check-circle-fill v-if="company" class="verified" variant="primary" />
                    </h4>
                </router-link>
                <p class="text-muted">
                    {{ mainInfo.description }}
                </p>
            </div>
            <div class="ml-auto">
                <div class="subscribe" v-if="!isThatU">
                    <button @click="subscribe" class="main" v-if="!mainInfo.subscribe">
                        <img src="@/assets/subscribe.png" class="mr-1" alt="">
                        <span class="follow">{{ t('follow') }}</span>
                    </button>
                    <button @click="unsubscribe" class="btn unsubscribe text-white fw-bold" v-else>
                        <img src="@/assets/unsubscribe.png" alt="" class="mr-1">
                        <span class="follow">{{ t('unfollow') }}</span>
                    </button>
                </div>
                <div v-else-if="!$store.state.userHasCompany && showChangeBtn != undefined" class="subscribe">
                    <button v-b-modal.editUser class="main">
                        <b-icon-pencil-square />
                        <span class="ml-2 follow">{{ t('change') }}</span>
                    </button>
                </div>
                <div v-else-if="showChangeBtn != undefined" class="subscribe">
                    <button v-b-modal.editCompany class="main">
                        <b-icon-pencil-square />
                        <span class="ml-2 follow">{{ t('change') }}</span>
                    </button>
                </div>
            </div>
        </div>

        <p v-if="mainInfo.subCount" class="followers-count">
            <b-icon-person-fill /> {{ t('followers') + mainInfo.subCount }}
        </p>
        <div v-if="socials" class="socials pl-5 pb-2 pr-2">
            <a :href="socials.telegram.includes('http') ? socials.telegram : 'https://www.t.me/' +  socials.telegram" 
                v-if="socials.telegram" 
                class="d-flex align-items-center mt-1"
            >
                <button class="btn"><img src="@/assets/telegram.png" class="telegram-icon" alt=""></button>
                <p class="mb-1" >{{ socials.telegram }}</p>
            </a>
            <a :href="socials.instagram.includes('http') ? socials.instagram : 'https://www.instagram.com/' +  socials.instagram" 
                v-if="socials.instagram"
                class="d-flex align-items-center mt-1"
            >
                <button class="btn"><b-icon-instagram /></button>
                <p class="mb-1" >{{ socials.instagram }}</p>
            </a>
            <a :href="socials.facebook.includes('http') ? socials.facebook : 'https://facebook.com/' + socials.facebook" 
                v-if="socials.facebook" 
                class="d-flex align-items-center mt-1"
            >
                <button class="btn"><b-icon-facebook /></button>
                <p class="mb-1" >{{ socials.facebook }}</p>
            </a>
            <a :href="socials.web" v-if="socials.web" class="d-flex align-items-center mt-1">
                <button class="btn"><b-icon-globe /></button>
                <p class="mb-1" >{{ socials.web }}</p>
            </a>
        </div>
        <b-modal size="lg" id="editCompany" :title="t('changeInfo')" hide-footer @hide="modalHiden()">
            <edit-company :socials="socials" :mainInfo="mainInfo" :company="company" :hideThumb="hideThumb" />
        </b-modal>
        <b-modal size="lg" id="editUser" :title="t('changeInfo')" hide-footer @hide="modalHiden()">
            <edit-user :socials="socials" :mainInfo="mainInfo" :company="company" :hideThumb="hideThumb" />
        </b-modal>
    </div>
</template>

<script>
import EditCompany from './EditCompany.vue'
import EditUser from './EditUser.vue'

export default {
    name: 'userHeader',
    components: { EditCompany, EditUser },
    methods: {
        modalHiden() {
            this.$emit('updateHeader')
        },
        async getOwnerId() {
            const resp = await this.axios(`company/owner/` + this.mainInfo.id)
            return resp.data.data.id
        },
        async subscribe() {
            if(!this.$store.state.loggedIn) {
                this.$router.push('/login')
                return;
            }
            const owner = this.company ? await this.getOwnerId() : this.mainInfo.id
            const resp = await this.axios.post(`subscribe`, { target_id: owner })
            if(resp.data.status == "Success") {
                this.mainInfo.subscribe = true;
                this.alert(this.t('you') + this.mainInfo.name + this.t('startedFollowing'))
            }
        },
        async unsubscribe() {
            const owner = this.company ? await this.getOwnerId() : this.mainInfo.id
            const resp = await this.axios.post(`subscribe/unsub`, { target_id: owner })
            if(resp.data.status == "Success") {
                this.mainInfo.subscribe = false;
                this.warning(this.t('you') + this.mainInfo.name + this.t('stoppedFollowing'))
            }
        }
    },
    computed: {
        isThatU() {
            if(this) {
                this.$forceUpdate()
                if(this.company)
                    return this.mainInfo.id == this.$store.state.usersCompany.id
                else
                    return this.mainInfo.id == this.$store.state.userInfo.id
            } else {
                return false
            }
        }
    },
    props: [
        'socials',
        'mainInfo',
        'company',
        'hideThumb',
        'showChangeBtn'
    ]
}
</script>

<style scoped>
    .telegram-icon {
        width: 20px;
    }
    .mr-1 {
        margin-right: 5px;
    }
    .header-card {
        margin: 0 auto;
        border-radius: 12px;
        overflow: hidden;
        min-height: 100px;
        background-color: white;
        width: 790px;
    }
    .thumb {
        height: 200px;
        background-color: white;
        background-position: center;
        background-size: cover;
        background-image: url(../assets/default-thumb.jpg);
    }
    .content {
        padding: 20px 20px 0 20px;
    }
    .profile-pic {
        min-width: 50px;
        max-width: 50px;
        background-size: cover;
        background-position: center;
        height: 50px;
    }
    .verified {
        margin-left: 8px;
        font-size: 16px;
    }
    .description {
        width: 60%;
        padding-left: 20px;
    }
    .followers-count {
        padding-left: 62px;
    }
    .followers-count svg {
        margin-right: 10px;
    }
    .description > h4 {
        font-weight: 800;
        margin-bottom: 0;
    }
    .subscribe {
        margin-left: auto;
    }
    .subscribe img {
        width: 16px;
    }
    .unsubscribe {
        background-color: rgba(128, 128, 128, 0.4);
    }
    .router-link {
        max-width: max-content;
        padding: 3px 10px;
        margin: -3px -10px;
        margin-right: 50px;
    }
    .router-link:hover {
        border-radius: 15px;
        height: max-content;
        background-color: rgba(128, 128, 128, 0.1);
    }
    @media (max-width: 991px) {
        .header-card {
            width: 100%;
        }
    }
    @media (max-width: 767px) {
        .header-card {
            border-radius: 0 !important;
        }
        .content {
            padding: 2vw;
        }
        .socials {
            padding-left: 2vw !important;
        }
    }
    @media (max-width: 540px) {
        .description {
            width: 100%;
        }
        .thumb {
            height: 120px;
        }
        h3 {
            font-size: 20px;
        }
        .follow {
            display: none;
        }
    }
</style>