<template>
    <div>
        <b-form-file v-model="thumb" accept="image/jpeg, image/png" placeholder="" class="thumbnail-drop" />
        <user-header-copy :company="company" :mainInfo="infoCopy" />
        <b-card no-body>
            <b-tabs card>
                <b-tab :title="t(`personalName`)" active>
                    <b-card-text>
                        <h3 class="mb-3">{{ t('changePersonalName') }}</h3>
                        <div>
                            <b-input v-model="infoCopy.name" />
                        </div>
                        <b-button :disabled="eventDisabled" class="main rounded mt-3" @click="changeName()">{{ t('changePersonalName') }}</b-button>
                    </b-card-text>
                </b-tab>
                <b-tab :title="t(`ava`)">
                    <b-card-text>
                        <h4 class="mb-3">{{ t('changeProfilePhoto') }}</h4>
                        <div class="row align-items-center">
                            <file-drop @imageChange="avaChange" class="file-drop" />
                            <b-button :disabled="eventDisabled || !ava" class="main mt-3 ml-4" @click="setAvatar()">{{ t('changeAva') }}</b-button>
                        </div>
                    </b-card-text>
                </b-tab>
                <b-tab :title="t(`thumb`)">
                    <b-card-text>
                        <h4 class="mb-3">{{ t('changeUpThumb') }}</h4>
                        <b-form-file
                            v-model="thumb"
                            accept="image/jpeg, image/png"
                            :placeholder="t('chooseThumb')"
                        />
                        <button class="main rounded mt-3" :disabled="eventDisabled" @click="changeThumb()">{{ t('changeThumb') }}</button>
                    </b-card-text>
                </b-tab>
                <b-tab :title="t(`deleteAcc`)" active>
                    <b-card-text>
                        <form autocomplete="off">
                            <h3 class="mb-3">{{ t('deleteAcc') }}</h3>
                            <div class="row">
                                <div class="col-10">
                                    <b-input :type="type" :placeholder="t(`password`)" v-model="infoCopy.password" />
                                </div>
                                <div class="col-2">
                                    <b-button variant="outline-dark" @click="type = type == 'password' ? 'text': 'password'"> <b-icon-eye /> </b-button>
                                </div>
                            </div>
                            <b-button variant="danger" :disabled="eventDisabled" class="rounded mt-3" @click="deleteAcc()">{{ t('deleteAcc') }}</b-button>
                        </form>
                    </b-card-text>
                </b-tab>
                <b-tab :title="t(`password`)" active>
                    <b-card-text>
                        <form autocomplete="off">
                            <h3 class="mb-3">{{ t('changePassword') }}</h3>
                            <div class="row">
                                <div class="col-10">
                                    <b-input :type="type" :placeholder="t(`enterNewPassword`)" v-model="infoCopy.password" />
                                    <b-input :type="type" :placeholder="t(`repeatNewPassword`)" v-model="infoCopy.password2" class="mt-3" />
                                </div>
                                <div class="col-2">
                                    <b-button variant="outline-dark" @click="type = type == 'password' ? 'text': 'password'"> <b-icon-eye /> </b-button>
                                </div>
                            </div>
                            <b-button :disabled="eventDisabled" class="main rounded mt-3" @click="changePassword()">{{ t('changePassword') }}</b-button>
                        </form>
                    </b-card-text>
                </b-tab>
            </b-tabs>
        </b-card>
        <b-modal id="crop" hide-footer size="lg" title="">
            <vue-cropper
                ref="cropper"
                :src="infoCopy.preview"
                :aspect-ratio="768 / 200"
            />
            <div class="d-flex justify-content-end">
                <button class="main mt-2" @click="crop()">{{ t('crop') }}</button>
            </div>
        </b-modal>
    </div>
    
</template>

<script>
import FileDrop from './FileDrop.vue'
import UserHeaderCopy from './userHeaderCopy.vue'
import cookies from 'vue-cookies'
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
export default {
    name: 'editUser',
    components: { UserHeaderCopy, FileDrop, VueCropper },
    data() { 
        return {
            ava: null,
            type: 'password',
            infoCopy: {},
            thumb: null,
            thumbFinal: null,
            eventDisabled: false,
        }
    },
    mounted() {
        this.infoCopy = {...this.mainInfo}
    },
    methods: {
        avaChange(file) {
            if(file && String(file.type).includes('image')) {
                this.ava = file
                const info = this.infoCopy
                const reader = new FileReader()
                reader.onloadend = function (e) {
                    info.avatar = e.target.result;
                }
                reader.readAsDataURL(file)
            }
        },
        async changeName() {
            this.eventDisabled = true
            this.changeThumb()
            try {
                await this.axios.post('user/name', {
                    name: this.infoCopy.name,
                })
                this.alert(this.t('personalNameHasChanged'))
            } catch(ignored) {0}

            setTimeout(() => {
                this.eventDisabled = false
            }, 3000)
        },
        async deleteAcc() {
            this.eventDisabled = true
            if(!this.infoCopy.password) {
                this.error(this.t('wrongPassword'))
            } else {
                try {
                    await this.axios.post('user/current/destroy', { password : this.infoCopy.password })
                    cookies.remove('token')
                    this.$router.push('/')
                    location.reload()
                } catch(error) {
                    this.error(this.t('wrongPassword'))
                }
            }
            setTimeout(() => {
                this.eventDisabled = false
            }, 3000)
        },
        async changePassword() {
            this.eventDisabled = true
            this.changeThumb()
            if(this.infoCopy.password != this.infoCopy.password2) {
                this.warning(this.t('passwordRetypedWrong'))
            } else if(!this.infoCopy.password || this.infoCopy.password.length < 8) {
                this.warning(this.t('passwordTooShort'))
            } else {
                try {
                    await this.axios.post('user/pass', {
                        password: this.infoCopy.password,
                    })
                    this.alert(this.t('passwordHasChanged'))
                } catch(ignored) {0}
            }

            setTimeout(() => {
                this.eventDisabled = false
            }, 3000)
        },
        async changeThumb() {
            this.eventDisabled = true
            if(this.thumb) {
                try {
                    const formData = new FormData();
                    formData.append("preview", this.thumbFinal || this.thumb);
                    this.axios.post('preview', formData);
                    this.alert(this.t('thumbnailChanged'))
                } catch(ignored) {0}
            }

            setTimeout(() => {
                this.eventDisabled = false
            }, 3000)
        },
        async setAvatar() {
            this.eventDisabled = true
            this.changeThumb()
            const formData = new FormData();
            formData.append("avatar", this.ava);
            try {
                await this.axios.post('user/avatar', formData);
                this.alert(this.t('avaHasChanged'))
            } catch(ignored) {0}

            setTimeout(() => {
                this.eventDisabled = false
            }, 3000)
        },
        crop() {
            const reader = new FileReader()
            this.$refs.cropper.getCroppedCanvas().toBlob(blob => {
                reader.onloadend = (e) => {
                    this.infoCopy.preview = e.target.result
                    this.$bvModal.hide('crop')
                }
                reader.readAsDataURL(blob)
                this.thumbFinal = blob
            })
        },
    },
    watch: {
        thumb() {
            this.thumbFinal = null
            const reader = new FileReader()
            reader.onloadend = (e) => {
                this.infoCopy.preview = e.target.result;
                this.$bvModal.show('crop')
                setTimeout(() => {
                    const cropper = this.$refs.cropper.cropper
                    cropper.cropBoxData = Object.assign(cropper.cropBoxData, cropper.containerData)
                    this.$forceUpdate()
                }, 500)
            }
            reader.readAsDataURL(this.thumb)
        }
    },
    props: [
        'mainInfo',
        'company',
        'hideThumb',
    ]
}
</script>

<style scoped>
    .file-drop {
        overflow: hidden;
        border-radius: 50%;
        min-height: 200px !important;
        min-width: 200px !important;
        max-height: 200px !important;
        max-width: 200px !important;
    }
    .telegram-icon {
        width: 20px;
    }
</style>