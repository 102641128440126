<template>
    <div>
        <user-header-copy :socials="socialsCopy" :hideThumb="hideThumb" :company="company" :mainInfo="infoCopy" />
        <b-card no-body>
            <b-tabs card>
                <b-tab :title="t(`name`)" active>
                    <b-card-text>
                        <h3 class="mb-3">{{ t('changeCompName') }}</h3>
                        <div class="d-flex align-items-center">
                            <b-input v-model="infoCopy.name" />
                            <b-icon-check-circle-fill v-if="company" class="verified ml-3" variant="primary" />
                        </div>
                        <button class="main rounded mt-3" @click="changeName()">{{ t('changeName') }}</button>
                    </b-card-text>
                </b-tab>
                <b-tab :title="t(`description`)">
                    <b-card-text>
                        <h3 class="mb-3">{{ t('changeCompDescription') }}</h3>
                        <b-form-textarea
                            v-model="infoCopy.description"
                            rows="3"
                            no-resize
                            class="mb-2 textarea"
                        />
                        <button class="main rounded mt-3" @click="changeDescription()">{{ t('changeDescription') }}</button>
                    </b-card-text>
                </b-tab>
                <b-tab :title="t(`ava`)">
                    <b-card-text>
                        <h4 class="mb-3">{{ t('changeProfilePhoto') }}</h4>
                        <div class="row align-items-center">
                            <file-drop @imageChange="avaChange" class="file-drop" />
                            <button class="main mt-3 ml-4" @click="setAvatar()">{{ t('changeAva') }}</button>
                        </div>
                    </b-card-text>
                </b-tab>
                <b-tab :title="t(`thumb`)">
                    <b-card-text>
                        <h4 class="mb-3">{{ t('changeUpThumb') }}</h4>
                        <b-form-file
                            v-model="thumb"
                            accept="image/jpeg, image/png"
                            :state="Boolean(thumb)"
                            :placeholder="t('chooseThumb')"
                        />
                        <button class="main rounded mt-3 " @click="changeThumb()">{{ t('changeThumb') }}</button>
                    </b-card-text>
                </b-tab>
                <b-tab :title="t(`socials`)">
                    <b-card-text>
                        <h4 class="mb-3">{{ t('socialLinks') }}</h4>
                        <div class="d-flex mt-3">
                            <button class="btn"><img src="@/assets/telegram.png" class="telegram-icon" alt=""></button>
                            <b-input v-model="socialsCopy.telegram" placeholder="https://www.t.me/..." />
                        </div>
                        <div class="d-flex mt-3">
                            <button class="btn"><b-icon-instagram /></button>
                            <b-input v-model="socialsCopy.instagram" placeholder="https://www.instagram.com/..." />
                        </div>
                        <div class="d-flex mt-3">
                            <button class="btn"><b-icon-facebook /></button>
                            <b-input v-model="socialsCopy.facebook" placeholder="https://www.facebook.com/..." />
                        </div>
                        <div class="d-flex mt-3">
                            <button class="btn"><b-icon-globe /></button>
                            <b-input v-model="socialsCopy.web" placeholder="https://www...." />
                        </div>
                        <br>
                        <button class="main rounded mt-3 " @click="setSocials()">{{ t('submitLinks') }}</button>
                    </b-card-text>
                </b-tab>
                <b-tab :title="t(`password`)" active>
                    <b-card-text>
                        <h3 class="mb-3">{{ t('changePassword') }}</h3>
                        <div class="row align-items-center">
                            <div class="col-10">
                                <b-input :type="type" :placeholder="t(`enterNewPassword`)" v-model="infoCopy.password" />
                                <b-input :type="type" :placeholder="t(`repeatNewPassword`)" v-model="infoCopy.password2" class="mt-3" />
                            </div>
                            <div class="col-2">
                                <b-button @click="type = type == 'password' ? 'text': 'password'"> <b-icon-eye /> </b-button>
                            </div>
                        </div>
                        <b-button :disabled="eventDisabled" class="main rounded mt-3" @click="changePassword()">{{ t('changePassword') }}</b-button>
                    </b-card-text>
                </b-tab>
            </b-tabs>
        </b-card>
    </div>
    
</template>

<script>
import FileDrop from './FileDrop.vue'
import UserHeaderCopy from './userHeaderCopy.vue'
export default {
    name: 'editCompany',
    components: { UserHeaderCopy, FileDrop },
    data() { 
        return {
            eventDisabled: false,
            type: 'password',
            ava: {},
            thumb: [],
            infoCopy: {},
            socialsCopy: {}
        }
    },
    mounted() {
        this.infoCopy = {...this.mainInfo}
        this.socialsCopy = {...this.socials}
    },
    methods: {
        avaChange(file) {
            if(file && String(file.type).includes('image')) {
                this.ava = file
                const info = this.infoCopy
                const reader = new FileReader()
                reader.onloadend = function (e) {
                    info.avatar = e.target.result;
                }
                reader.readAsDataURL(file)
            }
        },
        async changeName() {
            await this.axios.post('company/edit/name', {
                company_id: this.mainInfo.id,
                name: this.infoCopy.name,
            }).catch(() => {
                this.error(this.t('suchCompanyExists'))
            })
            this.alert(this.t('NameHasChanged'))
        },
        async changeDescription() {
            await this.axios.post('company/edit/description', {
                company_id: this.mainInfo.id,
                description: this.infoCopy.description,
            })
            this.alert(this.t('descriptionHasChanged'))
        },
        async setAvatar() {
            const formData = new FormData();
            formData.append("company_id", this.mainInfo.id);
            formData.append("logo", this.ava);
            await this.axios.post('company/setLogo', formData);
            this.alert(this.t('avaHasChanged'))
        },
        async changeThumb() {
            const formData = new FormData();
            formData.append("company_id", this.mainInfo.id);
            formData.append("preview", this.thumb);
            await this.axios.post('company/preview', formData);
            this.alert(this.t('thumbnailChanged'))
        },
        async setSocials() {
            const socials = {
                company_id: this.mainInfo.id,
                web: this.socialsCopy?.web || '',
                telegram: this.socialsCopy?.telegram || '',
                instagram: this.socialsCopy?.instagram || '',
                facebook: this.socialsCopy?.facebook || '',
            }
            Object.keys(socials).forEach((social) => {
                if(!socials[social]) {
                    delete socials[social]
                }
            })
            await this.axios.post('company/social', socials);
            this.alert(this.t('socialsHaveChanged'))
        },
        async changePassword() {
            this.eventDisabled = true
            if(this.infoCopy.password != this.infoCopy.password2) {
                this.warning(this.t('passwordRetypedWrong'))
                return ;
            } else if(!this.infoCopy.password || this.infoCopy.password.length < 8) {
                this.warning(this.t('passwordTooShort'))
                return ;
            }
            await this.axios.post('user/pass', {
                password: this.infoCopy.password,
            })
            this.alert(this.t('passwordHasChanged'))

            setTimeout(() => {
                this.eventDisabled = false
            }, 3000)
        },
    },
    watch: {
        thumb() {
            const info = this.infoCopy
            const reader = new FileReader()
            reader.onloadend = function (e) {
                info.preview = e.target.result;
            }
            reader.readAsDataURL(this.thumb)
        }
    },
    props: [
        'mainInfo',
        'company',
        'hideThumb',
        'socials',
    ]
}
</script>

<style scoped>
    .file-drop {
        overflow: hidden;
        border-radius: 50%;
        min-height: 200px !important;
        min-width: 200px !important;
        max-height: 200px !important;
        max-width: 200px !important;
    }
    .telegram-icon {
        width: 20px;
    }
</style>