<template>
    <div class="header-card w-100">
        <div 
            v-if="!hideThumb" 
            class="thumb w-100" 
            :style="mainInfo.preview ? {'background-image': `url(${ mainInfo.preview })`} : {}" 
        />
        <div class="d-flex content">
            <div class="profile-pic" :style="mainInfo.avatar ? {'background-image': `url(${mainInfo.avatar})`} : {}" />
            <div class="description">
                <router-link :to="(company ? '/company/' : '/user/') + mainInfo.id" class="d-flex router-link ">
                    <h4 class="d-flex align-items-center justify-content-between text-dark mt-2">
                        {{ mainInfo.name }} 
                        <b-icon-check-circle-fill v-if="company" class="verified" variant="primary" />
                    </h4>
                </router-link>
                <p class="text-muted">
                    {{ mainInfo.description }}
                </p>
            </div>
            <div class="subscribe">
                <button class="main">
                    <img src="@/assets/subscribe.png" class="mr-1" alt="">
                    <span class="follow">{{ t('follow') }}</span>
                </button>
            </div>
        </div>
        <div v-if="socials" class="socials pl-5 pb-2 pr-2">
            <a :href="socials.telegram.includes('http') ? socials.telegram : 'https://www.t.me/' +  socials.telegram" 
                v-if="socials.telegram" 
                class="d-flex align-items-center mt-1"
            >
                <button class="btn" disabled><img src="@/assets/telegram.png" class="telegram-icon" alt=""></button>
                <p class="mb-1" >{{ socials.telegram }}</p>
            </a>
            <a :href="socials.instagram.includes('http') ? socials.instagram : 'https://www.instagram.com/' +  socials.instagram" 
                v-if="socials.instagram"
                class="d-flex align-items-center mt-1"
            >
                <button class="btn" disabled><b-icon-instagram /></button>
                <p class="mb-1" >{{ socials.instagram }}</p>
            </a>
            <a :href="socials.facebook.includes('http') ? socials.facebook : 'https://facebook.com/' + socials.facebook" 
                v-if="socials.facebook" 
                class="d-flex align-items-center mt-1"
            >
                <button class="btn" disabled><b-icon-facebook /></button>
                <p class="mb-1" >{{ socials.facebook }}</p>
            </a>
            <a :href="socials.web" v-if="socials.web" class="d-flex align-items-center mt-1">
                <button class="btn" disabled><b-icon-globe /></button>
                <p class="mb-1" >{{ socials.web }}</p>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'userHeaderCopy',
    props: [
        'mainInfo',
        'company',
        'hideThumb',
        'socials',
    ]
}
</script>

<style scoped>
    .telegram-icon {
        width: 20px;
    }
    .mr-1 {
        margin-right: 5px;
    }
    .header-card {
        margin: 0 auto;
        border-radius: 12px;
        overflow: hidden;
        min-height: 100px;
        background-color: white;
        width: 790px;
    }
    .thumb {
        height: 200px;
        background-color: white;
        background-position: center;
        background-size: cover;
        background-image: url(../assets/default-thumb.jpg);
    }
    .content {
        padding: 20px 20px 0 20px;
    }
    .profile-pic {
        min-width: 50px;
        max-width: 50px;
        background-size: cover;
        background-position: center;
        height: 50px;
    }
    .verified {
        margin-left: 8px;
        font-size: 16px;
    }
    .description {
        width: 60%;
        padding-left: 20px;
    }
    .description > h4 {
        font-weight: 800;
        margin-bottom: 0;
    }
    .subscribe {
        margin-left: auto;
    }
    .subscribe img {
        width: 16px;
    }
    .unsubscribe {
        background-color: rgba(128, 128, 128, 0.4);
    }
    .router-link {
        max-width: max-content;
        padding: 3px 10px;
        margin: -3px -10px;
        margin-right: 50px;
    }
    .router-link:hover {
        border-radius: 15px;
        height: max-content;
        background-color: rgba(128, 128, 128, 0.1);
    }
    @media (max-width: 991px) {
        .header-card {
            width: 100%;
        }
    }
    @media (max-width: 767px) {
        .content {
            flex-wrap: wrap;
            padding-bottom: 20px;
        }
    }
    @media (max-width: 540px) {
        .description {
            width: 100%;
        }
        .thumb {
            height: 120px;
        }
        h3 {
            font-size: 20px;
        }
        .follow {
            display: none;
        }
    }
</style>